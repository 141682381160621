









































import LayerConfig from '@/models/map-context/LayerConfig';
import BaseComponent from '../BaseComponent';
import Component from 'vue-class-component';

@Component<Accordion>({
  props: {
    features: {
      type: Object,
      required: true,
      default: () => ({})
    },
    id: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    layer: {
      type: Object as () => LayerConfig,
      required: false,
      default: () => ({})
    }
  }
})
export default class Accordion extends BaseComponent {
  features!: any;

  id!: string;

  visible!: boolean;

  layer!: LayerConfig;

  expanded = false;

  mounted(){
    this.expanded = this.visible;
  }
  
  getLabel(key: any) {
    if (this.layer && this.layer.layerProperties) {
      const searchAttribute = this.layer.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.label !== undefined && searchAttribute.label !== '') {
        return searchAttribute.label;
      }
    }
    return key;
  }


  isBoolean(key:any) : Boolean{
    if (this.layer && this.layer.layerProperties) {
      const searchAttribute = this.layer.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.type ) {
        return searchAttribute.type === 'xsd:boolean';
      }
    }
    return false
  }

  isDisplay(key: any) {
    if (this.layer && this.layer.layerProperties) {
      const searchAttribute = this.layer.layerProperties.filter((data) => key === data.name)[0];
      if (searchAttribute === undefined || !searchAttribute.display) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  isUrl(value: any) {
    return typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'));
  }

  openInNewTab(url: string) {
    window.open(url, '_blank');
  }
  
}
