



































































































































import { Component, Vue } from 'vue-property-decorator';
import PluginComponent from './PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import LayerConfig from '@/models/map-context/LayerConfig';
import Accordion from '../map/Accordion.vue';
import axios from 'axios';
import { GeoJSON } from 'ol/format';

@Component<FeatureInfosPanelCustom>({
  props: {
  },
  components: {
    Accordion,
  },
})

export default class FeatureInfosPanelCustom extends PluginComponent {
  static ID='FeatureInfosPanelCustom';

  static NAME='FeatureInfosPanelCustom';

  private currentFeature:any;

  isFeatureSelected = false;

  isPci : boolean = false;

  public layerOfFeature: LayerConfig|null=null;

  edition = false;

  showList=false;

  featureIndex = 0;

  infosContent = '';

  siteFeature : any = null;

  buildingFeature : any= [];

  plotsFeature : any= null;

  corporationFeature : any = [];

  acte_propriete_file: any = null;
  
  currentPage: string = 'Site';

  openOnSelectedFeature(features:any):boolean{
    if(features.length>0 && (features[0].layerConfig.layername=='PG:maps_sites'
    || features[0].layerConfig.layername=='PG:maps_batiments'
    || features[0].layerConfig.layername=='PG:maps_parcelles'
    || features[0].layerConfig.layername=='CADASTRALPARCELS.PARCELLAIRE_EXPRESS:parcelle'
    )){
      return true
    }
    return false;
  }

  


  beforeDestroy():void {
    EventBus.$off(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
  }

  created() { 
  }

  mounted() {
    EventBus.$on(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
    const selectedFeatures = this.getMapService().selectionService.selectedFeature;
    if (selectedFeatures !== undefined) {
      this.onFeatureSelected(selectedFeatures);
    }
    
  }


  onFeatureSelected(features: any) {
      this.buildingFeature = [];
      this.plotsFeature = [];
      this.featureIndex = 0;
      this.currentFeature = features[this.featureIndex];
      this.isFeatureSelected = true;
      this.isPci = features[0].layerConfig.layername === 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS:parcelle';

      if(this.currentFeature){
        if( this.currentFeature.id_.startsWith("maps_batiments")){
          this.currentPage = 'Construction';
          this.buildingFeature = [this.currentFeature];
        }else if (this.currentFeature.id_.startsWith("maps_parcelles")){
          this.currentPage = 'Foncier';
          this.plotsFeature =  [this.currentFeature];
        }else if (this.currentFeature.id_.startsWith("maps_sites")){
          this.siteFeature =  this.currentFeature;
          this.currentPage = 'Site';
        }else if (this.currentFeature.id_.startsWith("parcelle")){
          this.currentPage = 'Foncier';
          this.plotsFeature =  this.currentFeature;
        }
      }else {
        // reset le panneau de modification
        this.currentFeature = null;
        this.layerOfFeature = null;
        this.isFeatureSelected = false;
      }
      this.getMapService().selectionService.addToSelection(this.currentFeature);
      this.getFeatureOnWfs()
  }


  async getFeatureOnWfs(){
    console.log("getFeatureOnWfs");
    // const regex = /^(.+)\.(\d+)$/;
    if(this.currentFeature.id_.startsWith("maps_sites"))
    {
      const params = this.siteFeature.getProperties().id_site
      const dataPlots: any = await this.getFeatureWfs( `id_site%3D'${params}'`, 'PG:maps_parcelles',this.currentFeature.layerConfig.url)
      const dataBuilding: any = await this.getFeatureWfs( `id_site%3D'${params}'`, 'PG:maps_batiments',this.currentFeature.layerConfig.url)
      this.plotsFeature = dataPlots;
      this.buildingFeature = dataBuilding;
      this.layerOfFeature = this.siteFeature.layerConfig;
      
    } else if (this.currentFeature.id_.startsWith("maps_parcelles"))
    {
      const params = this.plotsFeature[0].getProperties().id_site
      const data: any = await this.getFeatureWfs( `id_site%3D'${params}'`, 'PG:maps_batiments',this.currentFeature.layerConfig.url)
      const dataSite: any = await this.getFeatureWfs( `id_site%3D'${params}'`, 'PG:maps_sites',this.currentFeature.layerConfig.url)
      this.buildingFeature = data;
      this.siteFeature = dataSite[0]
      this.layerOfFeature = this.siteFeature.layerConfig;
    }else if (this.currentFeature.id_.startsWith("maps_batiments"))
    {
      const params = this.buildingFeature[0].getProperties().id_site
      const dataPlots: any = await this.getFeatureWfs( `id_site%3D'${params}'`, 'PG:maps_parcelles',this.currentFeature.layerConfig.url)
      const dataSite: any = await this.getFeatureWfs( `id_site%3D'${params}'`, 'PG:maps_sites',this.currentFeature.layerConfig.url)
      this.plotsFeature = dataPlots;
      this.siteFeature = dataSite[0];
      this.layerOfFeature = this.siteFeature.layerConfig;
    }else if (this.currentFeature.id_.startsWith("parcelle") && this.isPci)
    {
      const params = this.plotsFeature.getProperties().idu
      const corporation : any = await this.getWFSPrsMoral(`id_parcelle='${params}'`, 'PG:personnes_morales')
      this.corporationFeature = corporation;
      console.log('iciiiiiiiiii', corporation)
      this.layerOfFeature = this.plotsFeature.layerConfig;
    }
  }

  public async getWFSPrsMoral(filter: string, feature: string){
    const layer: any = this.contextService.getCurrentContext().getOrderedLayerList()
      .find((layer :any) => (layer.layername === "PG:maps_parcelles" || (layer.layername === "PG:maps_sites") || (layer.layername === "PG:maps_batiments")));
    const [layerUrl] = layer.url.split('?')
    const url = `${layerUrl}?service=WFS&version=1.0.0&REQUEST=GetFeature&typeNames=${feature}&outputformat=json&srsName=epsg:3857&cql_filter=${filter}`
    try {
        const res: any = await axios.get(url);
        const featureList = new GeoJSON().readFeatures(res.data);

        return featureList;
      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des données :", error);
        throw error;
      }
  }
  
  public async getFeatureWfs( filter: String, feature: String, headerUrl: string){
      const featureUrl = headerUrl.split('?')
      // const layerNameRegex = new RegExp(`^PG:${feature}$`, 'i');
      const url = `${featureUrl[0]}?service=wfs&version=2.0.0&REQUEST=GetFeature&typeNames=${feature}&outputformat=json&srsName=epsg:3857&cql_filter=${filter}`
      try {
        const res: any = await axios.get(url);
        const featureList = new GeoJSON().readFeatures(res.data);

        const filteredLayer = this.getMapService().getOrderedLayerList().find(layer => feature === layer.layername);
        if (filteredLayer) {
          featureList.forEach((feature: any) => {
            feature.layerConfig = filteredLayer;
          });
        }

        return featureList;
      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des données :", error);
        throw error;
      }
    }

  getLabel(key:any) {
    if (this.layerOfFeature && this.layerOfFeature.layerProperties) {
      const searchAttribute = this.layerOfFeature.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.label !== undefined && searchAttribute.label !== '') {
        return searchAttribute.label;
      }
    }

    return key;
  }

  isBoolean(key:any) : Boolean{
    if (this.layerOfFeature && this.layerOfFeature.layerProperties) {
      const searchAttribute = this.layerOfFeature.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.type ) {
        return searchAttribute.type === 'xsd:boolean';
      }
    }
    return false
  }

  isDisplay(key: any){
    if (this.layerOfFeature && this.layerOfFeature.layerProperties) {
      const searchAttribute = this.layerOfFeature.layerProperties.filter((data) => key === data.name)[0];
      if (searchAttribute === undefined || !searchAttribute.display) {
        return false;
      }else {
        return true;
      }
    }
    return true
  }

  getFeatureId(feature: any, feature_name: string) {
    if(feature_name === "batiment"){
      return feature.values_.id_bati;
    }else if(feature_name === "parcelle"){
      return feature.values_.id_parcelle
    }
      return String(feature.values_.id);
  }

  getFeatureValues(feature: any) {
    return feature.getProperties() || feature.properties;
  }



  isUrl(value: any) {
    return typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'));
  }

  openInNewTab(url: string) {
    window.open(url, '_blank');
  }

  changePage(page : string): void {
    this.currentPage = page;
  }

}
